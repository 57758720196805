import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import PropTypes from "prop-types"

import Container from "../container"

import Button from "../button"
import Link from "../link"
import Spacer from "../spacer"
import { WhiteH2Heading } from "../common/heading"

const Section = styled.section`
  background: ${(props) => props.theme.colorTeal};
  color: ${(props) => props.theme.colorWhite};
`
const Bottom = styled.div`
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
`
const Text = styled.div.attrs({ className: `styled-h4` })`
  width: ${rem(800)};
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  p {
    text-align: center;
    &:not(:first-of-type) {
      margin-top: ${rem(40)};
    }
  }
`
const ButtonRow = styled.div`
  color: ${(props) => props.theme.colorWhite};
  text-align: center;
  padding-top: ${rem(60)};
  border-top: 1px solid ${(props) => props.theme.colorWhite};
`

const UserResearch = (props) => (
  <Section>
    <Container>
      <Spacer top bottom>
        <WhiteH2Heading>{props.title}</WhiteH2Heading>
      </Spacer>
      <Bottom>
        <Spacer bottom>
          <Text dangerouslySetInnerHTML={{ __html: props.content }} />
        </Spacer>
        <Spacer bottom>
          <ButtonRow>
            <Link to={props.link.url}>
              <Button>{props.link.text}</Button>
            </Link>
          </ButtonRow>
        </Spacer>
      </Bottom>
    </Container>
  </Section>
)

UserResearch.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  link: PropTypes.shape({
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
}

export default UserResearch
