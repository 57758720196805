import React from "react"

const TreatmentArrow2 = (props) => (
  <svg
    width="691"
    height="367"
    viewBox="0 0 691 367"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      id="svg_ani_two"
      opacity="0.5"
      d="M 653.75 20.254 L 109.993 20.254 C 69.819 20.254 37.25 52.823 37.25 92.997 C 37.25 133.171 69.819 165.74 109.993 165.74 L 140 165.74 C 180.174 165.74 212.743 198.31 212.743 238.484 L 212.743 346.69 L 224.461 335.027 L 212.752 346.746 L 201.043 335.027"
      strokeLinejoin="round"
      strokeLinecap="round"
      fill="none"
    />
    <use
      href="#svg_ani_two"
      stroke="#000"
      strokeWidth="0"
      strokeDasharray="0"
    />
    <use
      className="aniFillTwo"
      id="aniFillTwo"
      href="#svg_ani_two"
      stroke="#fff"
      strokeWidth="4"
    />
  </svg>
)

export default TreatmentArrow2
