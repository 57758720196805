import React from "react"
import styled from "styled-components"
import { BgImage } from "gbimage-bridge"
import { getImage } from "gatsby-plugin-image"
import { gatsbyImageObject } from "../config/sharp-image-proptypes"
import PropTypes from "prop-types"

import colors from "../theme/sections/colors"

const Visual = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;

  > div {
    height: 100%;
  }
`

const CoverVisual = (props) => (
  <Visual data-testid="CoverVisual">
    <BgImage backgroundColor={colors.colorSand} image={getImage(props.image)} />
  </Visual>
)

CoverVisual.propTypes = {
  image: gatsbyImageObject,
  altText: PropTypes.string,
}

export default CoverVisual
