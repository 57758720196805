import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import PropTypes from "prop-types"

import Container from "../container"
import Spacer from "../spacer"
import Link from "../link"

import Button from "../button"

import Row from "../grid/row"
import Col from "../grid/column"
import { productNameFromContentfulProduct } from "../../utils/product-title"

const Wrapper = styled.section`
  background: ${(props) => props.theme.colorSand};
  color: ${(props) => props.theme.colorPaleGrey};
  text-align: center;
`

const Divider = styled.div`
  height: 1px;
  background: ${(props) => props.theme.colorBlack};
`

const BorderTop = styled.div`
  height: ${rem(40)};
  background: ${(props) => props.theme.colorLavender};
`

const BottomRow = styled(Row)`
  color: ${(props) => props.theme.colorBlack};
`

const RowHeading = styled.h4.attrs({ className: `styled-cue` })`
  text-align: left;
  color: ${(props) => props.theme.colorBlack};
`

const ProductEl = styled.div`
  font-weight: 300;
  line-height: 1.25;
  text-align: center;
  letter-spacing: 0.015em;

  @media ${(props) => props.theme.smallUp} {
    font-size: ${rem(48)};
  }

  @media ${(props) => props.theme.smallDown} {
    font-size: ${rem(25)};
  }

  .product {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 1 ${rem(420)};
    max-width: ${rem(420)};
    margin: 0 auto;

    & > :nth-child(even) {
      color: ${(props) => props.theme.colorBlack};
    }
  }
`

const Product = (props) => {
  return (
    <ProductEl>
      <Spacer top>
        <div className="product">
          <div>{props.productCode}</div>
          <div>
            {productNameFromContentfulProduct({
              productName: props.productName,
              productDisorder: props.productDisorder,
            })}
          </div>
        </div>
      </Spacer>
    </ProductEl>
  )
}

const Products = (props) => (
  <Wrapper data-testid="products-wrapper">
    <BorderTop />

    <Container>
      <Spacer top bottom>
        <h2
          className="styled-h2"
          dangerouslySetInnerHTML={{ __html: props.title }}
        />
      </Spacer>

      {props.categories.map((category, index) => (
        <div key={index}>
          <Spacer bottom>
            <Row>
              <Col smallUp={12}>
                <Divider />

                <Spacer top>
                  <RowHeading>{category.title}</RowHeading>
                </Spacer>
              </Col>
            </Row>

            <Row>
              {category.products.map((product, index) => (
                <Col key={index} smallUp={6}>
                  <Product {...product} />
                </Col>
              ))}
            </Row>
          </Spacer>
        </div>
      ))}

      <BottomRow>
        <Col smallUp={12}>
          <Divider />

          <Spacer top bottom>
            <Link to="/products">
              <Button>Learn more about our Products</Button>
            </Link>
          </Spacer>
        </Col>
      </BottomRow>
    </Container>
  </Wrapper>
)

Products.propTypes = {
  title: PropTypes.string,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      products: PropTypes.arrayOf(
        PropTypes.shape({
          productCode: PropTypes.string,
          productName: PropTypes.string,
          productDisorder: PropTypes.string,
        })
      ).isRequired,
    })
  ).isRequired,
}

Product.propTypes = {
  productCode: PropTypes.string,
  productName: PropTypes.string,
  productDisorder: PropTypes.string,
}

export default Products
