import styled from "styled-components"
import { rem } from "polished"

const Shadow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 0 ${rem(140)} ${rem(40)} ${rem(-50)} rgba(0, 0, 0, 0.2);
  z-index: 2;
`

export default Shadow
