import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import PropTypes from "prop-types"

import CoverVisual from "../cover-visual"
import Container from "../container"

import Row from "../grid/row"
import Col from "../grid/column"

const ContainerEl = styled.section`
  position: relative;
  text-align: center;
  color: ${(props) => props.theme.colorWhite};

  @media ${(props) => props.theme.smallUp} {
    padding-top: ${rem(215)};
    padding-bottom: ${rem(170)};
  }

  @media ${(props) => props.theme.smallDown} {
    padding-top: ${rem(80)};
    padding-bottom: ${rem(80)};
  }

  ${Container} {
    z-index: 2;
    position: relative;
  }
`

const StyledQuote = styled.blockquote.attrs({ className: `styled-quote` })`
  quotes: "\\201C""\\201D""\\2018""\\2019";
  p:first-child::before {
    content: open-quote;
  }
  p:last-child::after {
    content: close-quote;
  }
`

const QuoteAuthor = styled.h5.attrs({ className: `styled-label` })`
  margin-top: ${rem(44)};
`

const QuoteSection = (props) => (
  <ContainerEl>
    <Container>
      <Row>
        <Col smallUp={7}>
          <StyledQuote
            data-testid="styled-quote"
            dangerouslySetInnerHTML={{ __html: props.quote }}
          />

          <QuoteAuthor>{props.author}</QuoteAuthor>
        </Col>
      </Row>
    </Container>

    <CoverVisual image={props.image} />
  </ContainerEl>
)

QuoteSection.propTypes = {
  quote: PropTypes.string,
  author: PropTypes.string,
  image: PropTypes.object,
}

export default QuoteSection
