import React from "react"

const TreatmentArrow4 = (props) => (
  <svg
    fill="none"
    width="208"
    height="313"
    viewBox="0 0 207.9 313"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      id="svg_ani_four"
      d="M180,5.5h-60.5c-55.2,0-60,44.8-60,60v203 L 59.5 309.5 L 48.8 297.8 L 59.5 309.5 L 71.1 297.8 L 59.5 309.5"
      fill="none"
      opacity="0.5"
      strokeLinejoin="round"
      strokeLinecap="round"
    />
    <use
      href="#svg_ani_four"
      stroke="#000"
      strokeWidth="0"
      strokeDasharray="0"
    />
    <use
      className="aniFillFour"
      id="aniFillFour"
      href="#svg_ani_four"
      stroke="#fff"
      strokeWidth="4"
    />
  </svg>
)

export default TreatmentArrow4
