import React from "react"

const TreatmentArrowMobile2 = (props) => (
  <svg
    width="127"
    height="154"
    viewBox="0 0 127 154"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity="0.5"
      d="M116.061 153.061C115.475 153.646 114.525 153.646 113.939 153.061L104.393 143.515C103.808 142.929 103.808 141.979 104.393 141.393C104.979 140.808 105.929 140.808 106.515 141.393L115 149.879L123.485 141.393C124.071 140.808 125.021 140.808 125.607 141.393C126.192 141.979 126.192 142.929 125.607 143.515L116.061 153.061ZM3.5 0V27.5H0.5V0H3.5ZM39 63H78V66H39V63ZM116.5 101.5V152H113.5V101.5H116.5ZM3.5 27.5C3.5 47.1061 19.3939 63 39 63V66C17.737 66 0.5 48.763 0.5 27.5H3.5ZM78 63C99.263 63 116.5 80.237 116.5 101.5H113.5C113.5 81.8939 97.6061 66 78 66V63Z"
      fill="#DAD6D2"
    />
  </svg>
)

export default TreatmentArrowMobile2
