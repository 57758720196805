import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import PropTypes from "prop-types"

import Container from "../container"
import Spacer from "../spacer"

import Row from "../grid/row"
import Col from "../grid/column"

const Wrapper = styled.section`
  background: ${(props) => props.theme.colorPaleGrey};
  color: ${(props) => props.theme.colorWhite};
  text-align: center;
`

const Text = styled.div.attrs({ className: `styled-h4` })`
  p {
    &:not(:first-of-type) {
      margin-top: ${rem(40)};
    }
  }
`

const Privacy = (props) => (
  <Wrapper>
    <Container>
      <Spacer top>
        <h2
          className="styled-h2"
          dangerouslySetInnerHTML={{ __html: props.title }}
        />
      </Spacer>
      <Spacer top bottom>
        <Row>
          <Col smallUpOffset={2} smallUp={8}>
            <Text dangerouslySetInnerHTML={{ __html: props.content }} />
          </Col>
        </Row>
      </Spacer>
    </Container>
  </Wrapper>
)

Privacy.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
}

export default Privacy
