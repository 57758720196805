import React from "react"

const TreatmentArrow3 = (props) => (
  <svg
    width="544"
    height="267"
    viewBox="-7 0 544 267"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      id="svg_ani_three"
      opacity="0.5"
      d="M 31.782 7.708 L 361.52 7.708 C 450.899 7.708 523.355 77.291 523.355 163.127 L 523.355 224.125 L 523.355 259.292 L 534.591 248.938 L 523.366 259.292 L 511.965 248.773"
      strokeLinejoin="round"
      strokeLinecap="round"
      fill="none"
    />
    <use
      href="#svg_ani_three"
      stroke="#000"
      strokeWidth="0"
      strokeDasharray="0"
    />
    <use
      className="aniFillThree"
      id="aniFillThree"
      href="#svg_ani_three"
      stroke="#fff"
      strokeWidth="4"
    />
  </svg>
)

export default TreatmentArrow3
