import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { motion } from "framer-motion"

import CoverVisual from "./cover-visual"
import Shadow from "./hero-shadow"

const OuterContainer = styled(motion.div)`
  height: 100vh;
  overflow: hidden;
`

const Container = styled(motion.div)`
  height: 100vh;
  position: relative;
  overflow: hidden;
`

const TransitionTime = 0.75

const Hero = (props) => (
  <OuterContainer
    initial={{
      height: `100vh`,
    }}
    animate={{
      height: `80vh`,
      transition: {
        duration: TransitionTime,
        delay: TransitionTime,
        ease: `easeOut`,
      },
    }}
  >
    <Container
      initial={{
        y: 0,
      }}
      animate={{
        y: `-30px`,
        transition: {
          duration: TransitionTime,
          delay: TransitionTime,
          ease: `easeOut`,
        },
      }}
    >
      <Shadow />

      <CoverVisual image={props.image} altText={props.altText} />
    </Container>
  </OuterContainer>
)

Hero.propTypes = {
  image: PropTypes.object,
  altText: PropTypes.string,
}

export default Hero
