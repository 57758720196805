import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import Container from "../container"
import Spacer from "../spacer"
import { isArrayLongerThanCurrentIndex } from "../../utils/array-helpers"

import Row from "../grid/row"
import Col from "../grid/column"

import Arrow1 from "../svg/treatment-arrow-1"
import Arrow2 from "../svg/treatment-arrow-2"
import Arrow3 from "../svg/treatment-arrow-3"
import Arrow4 from "../svg/treatment-arrow-4"

import MobileArrow1 from "../svg/treatment-arrow-mobile-1"
import MobileArrow2 from "../svg/treatment-arrow-mobile-2"

const StyledArrow1 = styled((props) => <Arrow1 {...props} />)``
const StyledArrow2 = styled((props) => <Arrow2 {...props} />)``
const StyledArrow3 = styled((props) => <Arrow3 {...props} />)``
const StyledArrow4 = styled((props) => <Arrow4 {...props} />)``

const StyledMobileArrow1 = styled((props) => <MobileArrow1 {...props} />)`
  @media ${(props) => props.theme.xlargeUp} {
    display: none;
  }
`
const StyledMobileArrow2 = styled((props) => <MobileArrow2 {...props} />)`
  @media ${(props) => props.theme.xlargeUp} {
    display: none;
  }
`

const Wrapper = styled.section`
  background: ${(props) => props.theme.colorWode};
  color: ${(props) => props.theme.colorWhite};
  text-align: center;
`

const Step = styled.div`
  flex-shrink: 0;

  @media ${(props) => props.theme.xlargeUp} {
    width: ${rem(395)};
    width: 38.5%;
    max-width: 100%;
  }

  @media ${(props) => props.theme.xlargeDown} {
    width: 100%;
  }
`

const Heading = styled.h1.attrs({ className: `styled-h2` })`
  text-align: center;
`

const StepText = styled.div.attrs({ className: `styled-p` })`
  margin-top: ${rem(30)};
`

const StepNumber = styled.div.attrs({ className: `styled-h2` })``

const StepsRow = styled.div`
  @media ${(props) => props.theme.xlargeUp} {
    display: flex;
  }

  @media ${(props) => props.theme.xlargeDown} {
    width: ${rem(375)};
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;

    svg {
      width: ${rem(110)};
      margin: ${rem(20)} auto;
    }
  }

  &:nth-of-type(1) {
    ${StyledArrow1} {
      margin-top: 146px;
      margin-left: auto;
      margin-right: 177px;
      flex-shrink: 1;
      width: 486px;
      height: 261px;

      @media ${(props) => props.theme.xlargeUp} {
        display: block;
      }

      @media ${(props) => props.theme.xlargeDown} {
        display: none;
      }

      width: 77%;
      margin-right: 30%;
      height: auto;
      margin-top: ${rem(146)};
    }

    @media ${(props) => props.theme.smallDown} {
      svg {
        margin-left: 28%;
      }
    }
  }

  &:nth-of-type(2n) {
    ${StyledArrow2} {
      width: 688px;
      height: 361px;
      margin-top: 60px;
      margin-right: -64px;
      margin-left: 10px;
      flex-shrink: 1;

      @media ${(props) => props.theme.xlargeUp} {
        display: block;
      }

      @media ${(props) => props.theme.xlargeDown} {
        display: none;
      }

      height: auto;
      width: 108.7%;
      margin-right: -10%;
      margin-left: 2%;
      margin-top: ${rem(60)};
    }

    ${Step} {
      margin-left: auto;
      order: 1;
    }

    @media ${(props) => props.theme.smallDown} {
      margin-left: auto;
      margin-right: auto;

      svg {
        margin-left: -28%;
      }
    }
  }

  &:nth-of-type(3) {
    ${StyledArrow3} {
      flex-shrink: 1;
      width: 525px;
      margin-left: -63px;
      margin-top: 56px;
      margin-right: 175px;

      @media ${(props) => props.theme.xlargeUp} {
        display: block;
      }

      @media ${(props) => props.theme.xlargeDown} {
        display: none;
      }

      width: 83%;
      margin-left: -9.8%;
      margin-right: 0;
      margin-top: ${rem(56)};
    }

    @media ${(props) => props.theme.smallDown} {
      svg {
        margin-left: 28%;
      }
    }
  }

  &:nth-of-type(4) {
    ${StyledArrow4} {
      flex-shrink: 1;

      @media ${(props) => props.theme.xlargeUp} {
        display: block;
      }

      @media ${(props) => props.theme.xlargeDown} {
        display: none;
      }

      width: 83%;
      margin-right: -10%;
      margin-left: 48%;
      margin-top: ${rem(73)};
    }

    @media ${(props) => props.theme.smallDown} {
      svg {
        margin-left: -28%;
      }
    }
  }

  &:nth-of-type(5) {
    ${Step} {
      margin-left: auto;
      margin-right: auto;

      h4 {
        @media ${(props) => props.theme.xlargeUp} {
          padding: 0 50px;
        }
      }
    }
  }
`

const StepsStyledRow = styled(Row)`
  @media ${(props) => props.theme.smallDown} {
    width: ${rem(335)};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
`

const StepsStyledCol = styled(Col)`
  @media ${(props) => props.theme.xlargeDown} {
    margin-left: 0;
    width: 100%;
  }
`

const ArrowContainer = styled.div`
  @media ${(props) => props.theme.xlargeUp} {
    width: 61.5%;
  }

  @media ${(props) => props.theme.xlargeDown} {
    width: 100%;
  }
`

const Treatment = (props) => (
  <div data-testid="treatment-wrapper">
    <Helmet>
      <script src="/svg-animation.js" />
    </Helmet>
    <Wrapper>
      <Container>
        <Spacer top bottom>
          <Heading dangerouslySetInnerHTML={{ __html: props.title }} />
        </Spacer>

        <Spacer bottom>
          <StepsStyledRow>
            <StepsStyledCol smallUp={10} smallUpOffset={1}>
              {props.steps.map((item, index) => {
                const hasAnotherStep = isArrayLongerThanCurrentIndex(
                  props.steps,
                  index
                )
                return (
                  <StepsRow key={item.stepId}>
                    <Step>
                      <StepNumber>{item.stepId}</StepNumber>
                      <h4 className="styled-h3">{item.title}</h4>
                      <StepText
                        dangerouslySetInnerHTML={{
                          __html: item.description,
                        }}
                      />
                    </Step>

                    {index == 0 && hasAnotherStep && (
                      <ArrowContainer>
                        <StyledMobileArrow1 />
                        <StyledArrow1 />
                      </ArrowContainer>
                    )}
                    {index == 1 && hasAnotherStep && (
                      <ArrowContainer>
                        <StyledMobileArrow2 />
                        <StyledArrow2 />
                      </ArrowContainer>
                    )}
                    {index == 2 && hasAnotherStep && (
                      <ArrowContainer>
                        <StyledMobileArrow1 />
                        <StyledArrow3 />
                      </ArrowContainer>
                    )}
                    {index == 3 && hasAnotherStep && (
                      <ArrowContainer>
                        <StyledMobileArrow2 />
                        <StyledArrow4 />
                      </ArrowContainer>
                    )}
                  </StepsRow>
                )
              })}
            </StepsStyledCol>
          </StepsStyledRow>
        </Spacer>
      </Container>
    </Wrapper>
  </div>
)

Treatment.propTypes = {
  title: PropTypes.string,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      stepId: PropTypes.string,
    })
  ).isRequired,
}

export default Treatment
