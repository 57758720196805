import React from "react"

const TreatmentArrowMobile1 = (props) => (
  <svg
    width="122"
    height="154"
    viewBox="0 0 122 154"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity="0.5"
      d="M10.4393 153.561C11.0251 154.146 11.9749 154.146 12.5607 153.561L22.1066 144.015C22.6924 143.429 22.6924 142.479 22.1066 141.893C21.5208 141.308 20.5711 141.308 19.9853 141.893L11.5 150.379L3.01472 141.893C2.42893 141.308 1.47919 141.308 0.893398 141.893C0.307611 142.479 0.307611 143.429 0.893398 144.015L10.4393 153.561ZM119 0.5V28H122V0.5H119ZM83.5 63.5H48.5V66.5H83.5V63.5ZM10 102V152.5H13V102H10ZM119 28C119 47.6061 103.106 63.5 83.5 63.5V66.5C104.763 66.5 122 49.263 122 28H119ZM48.5 63.5C27.237 63.5 10 80.737 10 102H13C13 82.3939 28.8939 66.5 48.5 66.5V63.5Z"
      fill="#DAD6D2"
    />
  </svg>
)

export default TreatmentArrowMobile1
