import styled from "styled-components"

export const GrayH2Heading = styled.h2.attrs({ className: `styled-h2` })`
  text-align: center;
  color: ${(props) => props.theme.colorPaleGrey};
`
export const WhiteH2Heading = styled.h2.attrs({ className: `styled-h2` })`
  text-align: center;
  color: ${(props) => props.theme.colorWhite};
`

export const CenteredH2Heading = styled.h2.attrs({ className: `styled-h2` })`
  text-align: center;
`
