import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import { StoreContext } from "../store"

import Meta from "../components/meta"
import QuoteSection from "../components/page-patients/quote-section"
import Hero from "../components/hero"
import TreatmentJourney from "../components/page-patients/treatment"
import Products from "../components/page-patients/products"
import Privacy from "../components/page-patients/privacy"
import UserResearch from "../components/page-patients/user-research"
import { getMarkdownRemarkContent } from "../utils/markdown-remark-content"
import { getMetaKeywords } from "../utils/meta-keywords-from-array"

class PatientsPageContent extends React.Component {
  static contextType = StoreContext

  componentDidMount() {
    this.context.setHeaderTheme(`bright`)
  }

  render() {
    const { data } = this.props
    const forPatients =
      data?.forPatients?.nodes.length > 0 ? data.forPatients.nodes[0] : {}

    return (
      <React.Fragment>
        <Meta
          description={forPatients.metaDescription ?? ``}
          keywords={getMetaKeywords(forPatients.metaKeywords)}
          title={forPatients.metaTitle ?? ``}
        />

        <Hero image={data.heroImage} />

        <TreatmentJourney
          steps={forPatients.treatmentJourney?.steps ?? []}
          title={
            forPatients.treatmentJourney?.title?.replace(/ /gu, `<br />`) ?? ``
          }
        />

        <QuoteSection
          author={forPatients.testimonialQuote?.author ?? ``}
          image={data.feedbackSectionImage}
          quote={getMarkdownRemarkContent(forPatients.testimonialQuote?.quote)}
        />

        <Products
          categories={forPatients.ourProducts?.categories ?? []}
          title={forPatients.ourProducts?.title ?? ``}
        />

        <UserResearch
          content={getMarkdownRemarkContent(forPatients.userResearch?.content)}
          link={{
            text: forPatients.userResearch?.formLinkButtonText ?? ``,
            url: forPatients.userResearch?.formLink ?? ``,
          }}
          title={forPatients.userResearch?.title ?? ``}
        />

        <Privacy
          content={getMarkdownRemarkContent(
            forPatients.patientPrivacy?.content
          )}
          title={
            forPatients.patientPrivacy?.title.replace(/ /u, `<br />`) ?? ``
          }
        />
      </React.Fragment>
    )
  }
}

PatientsPageContent.propTypes = {
  data: PropTypes.object,
}

const ForPatientsPage = (props) => (
  <StaticQuery
    query={graphql`
      query {
        heroImage: file(
          sourceInstanceName: { eq: "images" }
          relativePath: { eq: "temp/patients-hero.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              formats: AUTO
              placeholder: BLURRED
              breakpoints: [
                320
                480
                640
                768
                960
                1024
                1200
                1400
                1680
                1920
              ]
              backgroundColor: "transparent"
            )
          }
        }
        feedbackSectionImage: file(
          sourceInstanceName: { eq: "images" }
          relativePath: { eq: "temp/customer-feedback-bg.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              formats: AUTO
              placeholder: BLURRED
              breakpoints: [
                320
                480
                640
                768
                960
                1024
                1200
                1400
                1680
                1920
              ]
              backgroundColor: "transparent"
            )
          }
        }
        forPatients: allContentfulForPatientsPage(
          filter: { navigationUrl: { eq: "/for-patients" } }
          limit: 1
        ) {
          distinct(field: id)
          nodes {
            metaDescription
            metaKeywords
            metaTitle
            navigationPosition
            navigationTitle
            navigationUrl
            userResearch {
              title
              formLinkButtonText
              formLink
              content {
                childMarkdownRemark {
                  html
                }
              }
            }
            treatmentJourney {
              title
              steps {
                title
                description
                stepId
              }
            }
            testimonialQuote {
              author
              quote {
                childMarkdownRemark {
                  html
                }
              }
            }
            ourProducts {
              title
              categories {
                title
                products: pipelineProducts {
                  productName
                  productDisorder
                  productCode
                }
              }
            }
            patientPrivacy {
              title
              content {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <PatientsPageContent data={data} {...props} />}
  />
)

export default ForPatientsPage
